import {
    AllOnboardingProjectSets,
    alwaysAvailableProjectSetNames,
    hardcodedOnboardingProjectSets,
    OnboardingProjectSet,
    OnboardingProjectSets,
} from "@buildwithflux/constants";
import {featureFlags, FeatureFlagValues} from "@buildwithflux/core";
import {
    AutoLayoutApi,
    FallbackLegacyProPlanPrice,
    FallbackLegacyUltraPlanPrice,
    PlanPricing,
} from "@buildwithflux/models";
import {hasBrowserWindowAccess, Unsubscriber} from "@buildwithflux/shared";
import {produce} from "immer";
import {mapValues, set} from "lodash";
import {create, StoreApi, UseBoundStore} from "zustand";

import {FeatureFlagConnector, type FlagSet} from "../../../../modules/storage_engine/connectors/FeatureFlagConnector";
import {useEntitlement} from "../../../payments/state/plan";

type FeatureFlagState = {
    sourceUnsubscriber?: Unsubscriber;
} & FeatureFlagValues;

function getFluxFeatureFlagName(remoteName: string): keyof FeatureFlagValues | undefined {
    const result = Object.entries(featureFlags).find(([_fluxName, ldName]) => ldName === remoteName)?.[0];
    // NOTE: This is a safe cast, the compiler just doesn't know it because of the type of Object.entries
    return result as keyof FeatureFlagValues | undefined;
}

function mergeFlags(flags: FlagSet, currentState: FeatureFlagState): FeatureFlagState {
    if (!flags) return currentState;
    return produce(currentState, (draftState) => {
        for (const [flagName, flagValue] of Object.entries(flags)) {
            const fluxName = getFluxFeatureFlagName(flagName);
            if (!fluxName) {
                // This is because this feature flag is not used from the frontend - this feature flag can be safely ignored
            } else {
                // Some how we need to do a cast here even Typescript correctly inferred that `fluxName` is
                // of type "keyof FeatureFlagValues"
                (draftState as any)[fluxName] = flagValue;
            }
        }
    });
}

function emptyFlags(): Partial<FeatureFlagValues> {
    return mapValues(featureFlags, (_o) => undefined);
}

export function getInitialFlagValues(): FeatureFlagValues {
    const allFlags = FeatureFlagConnector?.allFlags();
    const result = emptyFlags();
    if (allFlags != null) {
        for (const [flagName, flagValue] of Object.entries(allFlags)) {
            const fluxName = getFluxFeatureFlagName(flagName);
            if (fluxName) {
                result[fluxName] = flagValue;
            }
        }
    }

    // TODO: This is NOT a safe cast: we are initializing every flag as undefined, including ones we type as an object
    return result as FeatureFlagValues;
}

export type UseFeatureFlagsStore = UseBoundStore<StoreApi<FeatureFlagState>>;
export const useFeatureFlags = create<FeatureFlagState>()((set, get) => {
    const unsubscriber = FeatureFlagConnector
        ? FeatureFlagConnector.subscribeToFlags((flags) => set(mergeFlags(flags, get())))
        : undefined;
    const initialFlagValues = getInitialFlagValues();

    return {
        unsubscriber,
        ...initialFlagValues,
    };
});

if (hasBrowserWindowAccess()) {
    set(window, ["__FLUX__", "useFeatureFlags"], useFeatureFlags);
}

const FALLBACK_FREE_USER_MAX_PRIVATE_PROJECTS = 5;
/**
 * @deprecated Should be using plans and entitlements services
 */
export const selectFreeUserMaxPrivateProjects = (state: FeatureFlagState) => {
    return state.freeUserMaxPrivateProjects ?? FALLBACK_FREE_USER_MAX_PRIVATE_PROJECTS;
};

export const selectLegacyProPlanPrice = (state: FeatureFlagState): PlanPricing => {
    const value = PlanPricing.safeParse(state.segmentTestLegacyProPlanPrice);

    if (value.success) {
        return value.data;
    }

    return FallbackLegacyProPlanPrice;
};

export const selectLegacyUltraPlanPrice = (state: FeatureFlagState): PlanPricing => {
    const value = PlanPricing.safeParse(state.segmentTestLegacyUltraPlan);

    if (value.success) {
        return value.data;
    }

    return FallbackLegacyUltraPlanPrice;
};

// Trivial function, used for fast toggling during dev
export const selectShowNewProjectDialog = (state: FeatureFlagState): boolean => {
    return state.segmentNewProjectDialog;
};

// Trivial function, used for fast toggling during dev
export const selectEnableCopilot1500 = (state: FeatureFlagState): boolean => {
    if (state.copilot1500 === "copilot1500") {
        return true;
    }
    return false;
};

export const selectAllOnboardingProjectSets = (state: FeatureFlagState): OnboardingProjectSets => {
    const allSets = AllOnboardingProjectSets.safeParse(state.onboardingProjectSetsList);
    if (!allSets.success) {
        return hardcodedOnboardingProjectSets;
    } else {
        return allSets.data;
    }
};

export const selectOnboardingProjectSet = (state: FeatureFlagState): OnboardingProjectSet => {
    const sets = selectAllOnboardingProjectSets(state);

    return sets[state.segmentOnboardingProjectSetName || alwaysAvailableProjectSetNames._default] || sets._default;
};

export const selectAutoLayoutVersion = (state: FeatureFlagState) =>
    AutoLayoutApi.versionParse(state.pcbAutoLayoutVersion);

export function usePcbAutoLayoutEnabled(): boolean {
    const featureFlag = useFeatureFlags((state) => state.pcbAutoLayout ?? false);
    const entitlement = useEntitlement("pcb_auto_layout").value;

    return featureFlag || entitlement;
}
